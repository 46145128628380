import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/Main.vue'),
    meta: { title: '银发经济与长者教育体系' },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { title: '银发经济与长者教育体系' },
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/Notice.vue'),
        meta: { title: '信息公告' },
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/Detail.vue'),
        meta: { title: '信息公告' },
      },
      {
        path: '/desc',
        name: 'desc',
        component: () => import('../views/Desc.vue'),
        meta: { title: '公司简介' },
      },
      {
        path: '/join',
        name: 'join',
        component: () => import('../views/Join.vue'),
        meta: { title: '招商加盟' },
      },
      {
        path: '/contcat',
        name: 'contcat',
        component: () => import('../views/Contcat.vue'),
        meta: { title: '联系我们' },
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originaPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originaPush.call(this, location).catch((err) => err);
};

router.afterEach((to) => {
  if (to.meta.title) document.title = to.meta.title;
});

export default router
