/*
 * axios封装
 * 请求拦截、相应拦截、错误统一处理
 */

import axios from "axios"
import router from "../router"
import store from "../store"

// 请求环境
const baseURL = process.env.NODE_ENV === "development" ? "http://192.168.2.4:9101/" : "https://platformserver.xianzdjkgd.com:8081/"
axios.defaults.baseURL = baseURL
axios.defaults.timeout = 10000
axios.defaults.headers["Content-Type"] = "application/json"

// 请求拦截
axios.interceptors.request.use(
  config => {
    const token = store.state.token
    token && (config.headers.token = token)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截
axios.interceptors.response.use(
  response => {
    const { status, data } = response
    const { url } = response.config
    if (status === 200) {
      if (url.includes("download")) {
        return Promise.resolve(response)
      }
      if (data.code === 100) {
        return Promise.resolve(data)
      } else {
        return Promise.reject(data)
      }
    } else {
      return Promise.reject(data)
    }
  },
  error => {
    if (error.code === "ECONNABORTED") {
      return Promise.reject({ desc: "网络连接超时，请重试" })
    } else {
      return Promise.reject({ desc: "服务器错误，请联系管理员" })
    }
  }
)

export default axios
