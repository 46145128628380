/**
 * 公共api
 */
export default {
  selectNoticeInfoAllList: "/notice/selectNoticeInfoAllList", //  查询所有信息公告
  selectNoticeInfoDetail: "/notice/selectNoticeInfoDetail", //  查询信息公告详情
  selectNoticeInfoNewList: "/notice/selectNoticeInfoNewList", //  查询最新信息公告

  insertCompanyApplyInfo: "/companyApply/insertCompanyApplyInfo", // 招商加盟-合作申请

  selectEnterpriseInfoDetail: "/enterpriseInfo/selectEnterpriseInfoDetail", // 查询公司信息详情

  pageList: "/adCarousel/pageList", // 轮播图列表
}
