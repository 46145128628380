import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './api/http'
import api from './api/api'
import ElementUI from 'element-ui';
import './assets/css/element-variables.scss';
import './assets/css/globle.scss';

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$api = api

Vue.use(ElementUI);

Vue.component('Headers', () => import('@/components/Headers.vue'));
Vue.component('Footers', () => import('@/components/Footers.vue'));
Vue.component('Title', () => import('@/components/Title.vue'));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
